import axios from "axios"

export const getRoles = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/roles").then(
      response => {
        console.log("roles queried successfully", response)
        resolve(response.data)
      },
      error => {
        console.log("getRoles ERROR: roles not found", error)
        reject(error)
      }
    )
  })
}

export const findByName = async name => {
  return new Promise(function(resolve, reject) {
    axios.get("/roles/findByName/" + name).then(
      response => {
        console.log("role queried successfully", response)
        resolve(response.data)
      },
      error => {
        console.log("getRoleByName ERROR: role not found", error)
        reject(error)
      }
    )
  })
}

export default {
  getRoles,
  findByName
}