import _ from "lodash"
import auth from "./auth"

export const parseMediaFileset = fileset => {
  // if a preview has been generated there will be 3 files (video and image)
  // if no preview has been generated there will only be a single file (audio, doc, archieve)
  return {
    source_file: fileset[0].id,
    thumbnail_file: fileset.length > 1 && fileset[1] ? fileset[1].id : null,
    webview_file: fileset.length > 2 && fileset[2] ? fileset[2].id : null,
    media: fileset[fileset.length - 1], // see note above. The "media" object is always the last in the fileset
    thumb: fileset.length > 1 ? fileset[1] : null,
  }
}

export const parseUserToLabel = user => {
  if (user && user.username && user.email) {
    return user.username + " (" + user.email + ")"
  } else if (user && user.username) {
    return user.username
  } else if (user && user.email) {
    return user.email
  } else if (_.isString(user)) {
    return user
  } else {
    return ""
  }
}

export const baseUrl = () => {
  return process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '1337' && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
  `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
  `http://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`;
}

const fixUrls = media => {

  let thumbnailUrl =  '';
  let webViewUrl = '';

  if (media.thumbnailUrl) {
    thumbnailUrl = media.thumbnailUrl;
    if (_.includes(media.thumbnailUrl, 'http://localhost:1337/')) {
      thumbnailUrl = `${baseUrl()}${media.thumbnailUrl.replace(
            "http://localhost:1337/",
            ""
          )}`
    } 
    else if (_.includes(media.thumbnailUrl, 'localhost:1337/')) {
      thumbnailUrl = `${baseUrl()}${media.thumbnailUrl.replace(
            "localhost:1337/",
            ""
          )}`
    } 
    else if (_.includes(media.thumbnailUrl, 'http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/')) {
      thumbnailUrl = `${baseUrl()}${media.thumbnailUrl.replace(
            "http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/",
            ""
          )}`
    }
  }
  

  if (media.webViewUrl) {
    webViewUrl = media.webViewUrl;
  
    if  (_.includes(media.webViewUrl, 'http://localhost:1337/')) {     
      webViewUrl = `${baseUrl()}${media.webViewUrl.replace(
            "http://localhost:1337/",
            ""
          )}`
    }
    else if (_.includes(media.webViewUrl, 'localhost:1337/')) {
      webViewUrl = `${baseUrl()}${media.webViewUrl.replace(
            "localhost:1337/",
            ""
          )}`
    } 
    else if (_.includes(media.webViewUrl, 'http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/')) {
      webViewUrl = `${baseUrl()}${media.webViewUrl.replace(
            "http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/",
            ""
          )}` 
    }
  }      

  return {
    ...media,
    webViewUrl: webViewUrl,
    thumbnailUrl: thumbnailUrl
  }
}

const isMobile = () => {
  if (auth.isBrowser()) {
    const width = window.innerWidth
    return width <= 768;  
  }
  else {
    return false
  }  
}

export default {
  parseMediaFileset,
  parseUserToLabel,
  baseUrl,
  fixUrls,
  isMobile
}
