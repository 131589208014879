import React, { useState } from "react"
const UploadStepContext = React.createContext()

const UploadStepProvider = ({ step, children }) => {
  const [_step, setStep] = useState(step ? step : 0)

  let ctx = {
    getStep: () => {
      return _step
    },
    setStep: setStep,
  }

  return (
    <UploadStepContext.Provider value={ctx}>
      {children}
    </UploadStepContext.Provider>
  )
}
export { UploadStepContext }
export default UploadStepProvider
