import axios from "axios"
import http from "http"
import moment from "moment"
import Auth, { CognitoUser } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import awsconfig from '../aws-exports';
import tokenHash from 'oidc-token-hash'
import roles from "./roles"
import organizations from "./organizations"
import Cryptr from 'cryptr'


// const host = process.env.GATSBY_FEDIA_DMS_HOST || "localhost"
// const port = process.env.GATSBY_FEDIA_DMS_PORT || 1337
// const baseUrl = `https://${host}:${port}`

Amplify.configure(awsconfig);

const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '1337' && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
  `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
  `http://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`;

axios.defaults.baseURL = baseUrl

const httpAgent = new http.Agent({
  keepAlive: true,
})
// const httpsAgent = new https.Agent({ keepAlive: true });

const instance = axios.create({
  httpAgent, // httpAgent: httpAgent -> for non es6 syntax
})

instance.interceptors.response.use(error => {
  console.log("AXIOS ERROR:", error)
})

export const isBrowser = () => typeof window !== "undefined"

const cryptr = new Cryptr('myTotallySecretKey')

const isAuthExpired = user => {
  let expiredMoment = moment().subtract(30, "days")

  if (!user || !user.authDate || expiredMoment.isAfter(user.authDate)) {
    console.log("AUTH: Expired")
    return true
  } else {
    return false
  }
}

const publicAuth = () => {
  console.log("AUTH: Clearing stored auth")
  delete axios.defaults.headers.common["Authorization"]
  return {}
}

export const getAuth = () => {
  if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
    const user = JSON.parse(window.localStorage.getItem("gatsbyUser"))
    if (user && user.jwt && !isAuthExpired(user)) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + user.jwt
      return user
    } else {
      return publicAuth()
    }
  } else {
    return publicAuth()
  }
}

let auth = getAuth()
console.log("AUTH current: ", auth)
if (auth.user && auth.user.jwt) {
  axios.get("users/me").then(
    response => {
      console.log("AUTH: current user: ", response.data)
    },
    error => {
      let auth = getAuth()
      if (auth.user && auth.user.jwt) window
      // no current auth. set user to public
      publicAuth()
      // refresh the page?
    }
  )
}

export const setAuth = user => {
  console.log(user)
  user.authDate = new Date()
  isBrowser() && window.localStorage.setItem("gatsbyUser", JSON.stringify(user))
  if (user && user.jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.jwt
  } else {
    delete axios.defaults.headers.common["Authorization"]
  }
}

export const handleLogin = function ({
  user,
  pass
}) {

  return new Promise(function (resolve, reject) {
    Auth.signIn(user, pass)
    .then(
      function (result) {
        let strapiPassword = ''
        if (isAFPABranch()) {
          const password = result.attributes['custom:password']
          strapiPassword = cryptr.decrypt(password);
        }
        else {
          strapiPassword = pass
        }  
        // const token = curSession.getAccessToken().getJwtToken();
        // console.log('Id Token:', result.signInUserSession.idToken.jwtToken)
        // console.log('Access Token:', result.signInUserSession.accessToken.jwtToken)
        // console.log('User:', result)
        // const hash = tokenHash.generate(result.signInUserSession.accessToken.jwtToken, 'RS256')
        // console.log('Hash:', hash)

        strapiLogin({user: user, pass: strapiPassword}).then(
          response => {
            resolve(response)
          },
          error => {
            console.log('Strapi login error:', error)
            reject(error)
          }
        )
      },
      function (e) {
        console.log(e)
        reject(e)
      }
    )
  })   

}

export const strapiLogin = function ({
  user,
  pass
}) {

  console.log('User', user, pass)
  delete axios.defaults.headers.common.Authorization
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "auth/local", {
          identifier: user,
          password: pass,
        }, {
          httpAgent,
        }
      )
      .then(
        function (auth) {
          console.log('Auth:', auth)
          setAuth(auth.data)
          resolve(auth)
        },
        function (e) {
          console.log(e)
          reject(e)
        }
      )
  })  
}

export const handleSignup = (userObj) => {

  console.log('UserObj:', userObj)

  return new Promise(function (resolve, reject) {
    let role = ''
    const encryptedString = cryptr.encrypt(userObj.password);

    Auth.signUp({
        username: userObj.email,
        password: userObj.password,
        attributes: {
          email: userObj.email,
          'custom:password': encryptedString
        }
      })
      .then(
        function (response) {
          roles.findByName('Industry Member').then(
            result => {
              role = result.entity.role.id

              let branch = ''
              if (process.env.GATSBY_USE_FHAA === 'true') {
                branch = 'FHAA'
              }
              else {
                branch = 'AFPA'
              }

              axios.get("branches/findByName/" + branch).then(
                branch => {
                  const userInfo = {
                    confirmed: true,
                    blocked: false,
                    email: userObj.email,
                    username: userObj.email,
                    password: userObj.password,
                    provider: "local",
                    role: role,
                    branch: branch.data.entity.id,
                    organization: userObj.id
                  };

                  createUser(userInfo).then(
                    user => {
                      resolve(user)
                      console.log(user)
                    },
                    error => {
                      console.log(error)
                      reject(error)
                    }
                  )
                },
                error => {
                  console.log(error)
                  reject(error)
                }
              )
            },
            error => {
              console.log(error)
              reject(error)
            }
          )
        },
        function (e) {
          console.log(e)
          reject(e)
        }
      )
    })
} 

export const resendConfirmation = (email) => {

  return new Promise(function (resolve, reject) {

    console.log('Email', email)

    Auth.resendSignUp(email)
      .then(
        function (response) {
          console.log('Resend:', response)
          resolve(response)
        },
        function (e) {
          console.log(e)
          reject(e)
        }
      )
    })
} 

export const forgotPassword = (email) => {

  return new Promise(function (resolve, reject) {
    Auth.forgotPassword(email)
      .then(
        function (response) {
          resolve(response)
        },
        function (e) {
          console.log(e)
          reject(e)
        }
      )
    })    
} 

export const forgotPasswordSubmit = ({email, password, code}) => {

  return new Promise(function (resolve, reject) {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(
        function (response) {
          resolve(response)
        },
        function (e) {
          console.log(e)
          reject(e)
        }
      )
    })    
} 

export const confirmSignup = (code, email) => {

  return new Promise(function (resolve, reject) {
    Auth.confirmSignUp(email.email, code)
    .then(
      function (response) {
        resolve(response)
      },
      function (e) {
        console.log(e)
        reject(e)
      }
    )

  })    
}  

export const createUser = (user) => {

  return new Promise(function (resolve, reject) {
    axios.post("/users", user).then(
      response => {
        console.log("Logged into strapi", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("Error", error)
        reject(error)
      }
    )
  })    
} 

export const isLoggedIn = () => {
  const user = getAuth()
  return !!user.jwt
}

//currently identical to isBranchAdmin. Might always be?
export const isBranchModerator = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
    const u = user.user
    return u.role && u.role.type === "super_user"
  }
}
export const isOrgModerator = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
    const u = user.user
    const isOrgModerator = u.role && (u.role.type === "industry_manager" || u.role.type === "org_manager")
    return (isOrgModerator) || isBranchAdmin()
  }
}

export const isAFPABranch = () => {
  let isAFPABranch = true
  if (process.env.GATSBY_USE_FHAA === 'true') {
    isAFPABranch = false
  }

  return (isAFPABranch)

}

// export const isOrgModerating = () => {
//   const user = getAuth()
//   if (!user || !user.user || !user.jwt) {
//     return false
//   } else {
//     const u = user.user
//     return u.organization && u.organization.moderate
//   }
// }

export const isModerator = () => {
  return isBranchModerator() || isOrgModerator()
}

export const isAuthorizedUser = () => {
  return isBranchModerator() || isOrgModerator() || isIndustryMember() || isBranchMember()
}

export const isBranchAdmin = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
    const u = user.user
    return u.role && u.role.type === "branch_admin"
  }
}

export const isIndustryMember = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
    const u = user.user
    return u.role && u.role.type === "industry_member"
  }
}

export const isBranchMember = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
    const u = user.user
    return u.role && u.role.type === "branch_member"
  }
}

export const isBranchModerating = () => {
  const authObj = getAuth()
  return authObj && authObj.user && authObj.user.isBranchModerating
}

export const isOrgModerating = () => {
  const authObj = getAuth()
  return authObj && authObj.user && authObj.user.isOrgModerating
}

export const isBranchOrOrgModerating = () => {
  const user = getAuth()
  if (!user || !user.user || !user.jwt) {
    return false
  } else {
      return isOrgModerating() || isBranchModerating();
  }
}

export const logout = callback => {
  setAuth({})
  callback()
}

export default {
  baseUrl,
  handleLogin,
  handleSignup,
  strapiLogin,
  confirmSignup,
  createUser,
  isAFPABranch,
  forgotPassword,
  isLoggedIn,
  isBranchModerator,
  isOrgModerator,
  isOrgModerating,
  isModerator,
  isBranchMember,
  isIndustryMember,
  isAuthorizedUser,
  isBranchAdmin,
  isBranchModerating,
  isBranchOrOrgModerating,
  logout,
  getAuth,
  setAuth,
  isBrowser
}