import axios from "axios"
import http from "http"
import _ from "lodash"

let alertModalOpen = false
let alertModalParams = {}
let _wrapperForceUpdateFn = null

const setWrapperForceUpdate = updateFn => {
  _wrapperForceUpdateFn = updateFn
}

const getAlertModalOpen = () => {
  return alertModalOpen
}
const getAlertModalParams = () => {
  return alertModalParams
}

/**
 * 
 * @param {*} value     
    setAlertParams,
    openAlertModal
 
 */
const setAlertModalOpen = (value, params) => {
  alertModalOpen = value
  if (params) {
    alertModalParams = params
  }
  _wrapperForceUpdateFn()
}

export default {
  setWrapperForceUpdate,
  getAlertModalOpen,
  setAlertModalOpen,
  getAlertModalParams,
}
